$text-muted: rgba(var(--uc-bodytextcolor-rgb), 0.7);
$modal-border-color: rgba(var(--uc-bodytextcolor-rgb), 0.125);

body {
  color: var(--uc-bodytextcolor);
}

.btn {
  color: var(--uc-bodytextcolor);
}

.text-muted {
  color: $text-muted !important;
}

.card {
  background-color: var(--uc-bodybackgroundcolor);
  border-color: $modal-border-color;
}

.card-header {
  background-color: rgba(var(--uc-bodytextcolor-rgb), 0.03);
  border-color: $modal-border-color;
}
.card .card-header h4 {
  color: var(--uc-bodytextcolor);
}

.input-group {
  background-color: var(--uc-bodybackgroundcolor);
  border-color: rgba(var(--uc-bodytextcolor-rgb), 0.6);
}
select {
  background-color: var(--uc-bodybackgroundcolor);
  color: var(--uc-bodytextcolor);
}

header .input-group {
  background-color: var(--uc-headerbackgroundcolor);
}

.predictive-search-container {
  background-color: var(--uc-bodybackgroundcolor);
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(var(--uc-bodytextcolor-rgb), 0.075) !important;
}

.list-group-item {
  background-color: var(--uc-bodybackgroundcolor);
  color: var(--uc-bodytextcolor);
  border-color: $modal-border-color;
}

.table {
  color: var(--uc-bodytextcolor);
}

tbody tr:nth-child(odd) {
  background-color: rgba(var(--uc-bodytextcolor-rgb), 0.2);
}
.table-striped > tbody > tr:nth-of-type(odd) > * {
  color: var(--uc-bodytextcolor);
}

.product-name {
  color: var(--uc-bodytextcolor);
  :hover {
    color: var(--uc-secondarycolour);
  }
}

.product-grid > div .card .card-body > a {
  color: $text-muted !important;
}

input,
.form-control {
  background-color: var(--uc-bodybackgroundcolor);
  color: var(--uc-bodytextcolor);
  &:focus {
    color: var(--uc-bodytextcolor);
    background-color: var(--uc-bodybackgroundcolor);
  }
}

.product-brand {
  color: $text-muted;
}

.accordion-cart .quote-input-box button.accordion-button {
  &:active,
  &:hover {
    background-color: var(--uc-bodybackgroundcolor);
  }
}
.accordion-cart .accordion-body {
  background-color: var(--uc-bodybackgroundcolor);
  color: var(--uc-bodytextcolor);
}

.accordion-item {
  background-color: var(--uc-bodybackgroundcolor);
}

.dropdown-menu {
  background-color: var(--uc-bodybackgroundcolor);
}

.accordion-absolute {
  background: var(--uc-bodybackgroundcolor);
  border-color: $modal-border-color !important;
}

.dropdown-menu {
  color: var(--uc-bodytextcolor);
}

.cart-product-name {
  color: var(--uc-bodytextcolor);
}

.filter-block .filter-list-container {
  .form-check-input,
  .form-check-label {
    color: $text-muted;
  }
}

.modal-content {
  background-color: var(--uc-bodybackgroundcolor);
  border-color: $modal-border-color;
}

span.text-black {
  color: #ffffff !important;
}