// Overwrites for black background

.color-black {
  color: var(--uc-bodytextcolor);
}

.bg-lightgray {
  background-color: #000000;
}

.white-background * {
  background-color: rgba(var(--uc-bodybackgroundcolor-rgb), 0.8);
}

.filter-block {
  color: #ffffff;
  background: #000000;
  border: 1px solid #fff;
}

.bg-light {
  background-color: #000000 !important;
}

.product-grid > div .card .card-body .h2 a,
.product-grid > div .card .card-body h2 a {
  color: #ffffff !important;
}

.text-primary {
  color: #ffffff !important;
}

.text-dark,
.mega-menu.dropdown-menu .nav-link,
.mega-menu.dropdown-menu a,
.modal .content-slider .carousel-caption {
  color: #ffffff !important;
}
.mega-menu.dropdown-menu .nav-link:hover,
.mega-menu.dropdown-menu a:hover {
  color: #ffffff !important;
}

.nav-shop-all {
  background-color: rgba(var(--uc-bodytextcolor-rgb), 0.2);
}

.bg-white {
  background-color: var(--uc-bodybackgroundcolor) !important;
}

input#flexSwitchCheckDefault {
  display: none;
}

.filterbar-toggle > span, i {
  color: #ffffff !important;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #ffffff;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.product-listing .sort-options button {
    background: #000;
    border-radius: 5px;
    color: #ffffff !important;
    padding: 0.5rem 1rem;
    border-color: #fff;
    font-size: 0.9375rem;
    min-width: 180px;
    text-align: left;
}

.navbar-brand {
  padding-top: 1.9125rem;
  padding-bottom: 0.3125rem;
  padding-left: 0.4125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
}